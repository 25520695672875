
<template>
  <div id="profile-page">
    <!-- My Details -->
    <vx-card class="mt-base">
      <ul class="page-suggestions-list">
        <li class="page-suggestion flex items-center mb-4">
          <div class="mr-3" v-if="!edit">
            <img
              v-if="activeUserInfo.photoURL"
              key="onlineImg"
              :src="activeUserInfo.photoURL? activeUserInfo.photoURL: activeUserInfo.profileImage"
              alt="user-img"
              width="50"
              height="50"
              class="rounded-full shadow-md cursor-pointer block"
            />
          
          </div>
          <div class="leading-tight">
            <p class="font-medium">My Details</p>
            <span class="text-xs">Manage your account details</span>
          
          </div>
          <div class="ml-auto">
            <div class="flex">
              <vs-button
                v-if="edit"
                icon-pack="feather"
                icon="icon-x"
                color="danger"
                type="filled"
                @click="cancelEdit()"
              >Cancel Edit</vs-button>
              <vs-button
                v-else
                icon-pack="feather"
                icon="icon-edit"
                color="primary"
                type="filled"
                @click="editProfile()"
              >Edit Profile</vs-button>
            </div>
          </div>
        </li>
      </ul>
    </vx-card>

    <!-- Edit Profile Detail -->
    <vx-card v-if="edit" title="Edit Profile Detail" class="mt-base">
      <ProfileEdit :userData="userDetail" />
    </vx-card>

    <!-- Profile Detail -->
    <vx-card v-else title="Profile Details" class="mt-base">
      <ProfileDetail />
    </vx-card>
  </div>
</template>

<script>
  import ProfileDetail from "@/views/components/profile/ProfileDetail";
  import ProfileEdit from "@/views/components/profile/ProfileEdit";

  export default {
    components: {
      ProfileDetail,
      ProfileEdit
    },
    data() {
      return {
        edit: false,
        userDetail: {},
        dataImg: null
      };
    },
    computed: {
      activeUserInfo() {
        return this.$store.state.AppActiveUser;
      }
    },
    methods: {
      editProfile() {
        this.edit = true;
      },
      cancelEdit() {
        this.edit = false;
      },
      updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();
        reader.onload = e => {
          this.dataImg = e.target.result
                          console.log("Image Before", this.dataImg, e.target)
        }
        reader.readAsDataURL(input.target.files[0])
                console.log("Image", this.dataImg)
      }
    }
    },
    created() {
      this.userDetail = this.$store.state.AppActiveUser;
    }
  };
</script>


<style lang="scss">
  @import "@/assets/scss/vuexy/pages/profile.scss";
</style>
