<template>
  <div>
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="1">
        <div class="w-full m-5">
         <img
              key="onlineImg"
              :src="dataImg ? dataImg : userDetail.photoURL"
              alt="user-img"
              width="50"
              height="50"
              class="rounded-full shadow-md cursor-pointer block"
            />
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-w="4">
        <div class="w-full m-5">
        <input type="file" 
            class="hidden"
            v-validate="'image'" data-vv-as="image"
            name="image_field"
            ref="updateImgInput" @change="updateCurrImg" accept="image/*"
            />
           
            <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">Change Image</vs-button>
             <span class="text-danger text-sm">
            {{ errors.first("image_field") }}
          </span>
      </div>
      </vs-col>
      </vs-row>
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('firstName')"
            :success="!errors.first('firstName') && userDetail.firstName != ''"
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required'"
            data-vv-validate-on="blur"
            data-vv-as="first name"
            name="firstName"
            label-placeholder="First Name"
            placeholder="FIrst Name"
            v-model="userDetail.firstName"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">
            {{ errors.first("firstName") }}
          </span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <vs-input
            :danger="errors.first('lastName')"
            :success="!errors.first('lastName') && userDetail.lastName != ''"
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required'"
            data-vv-validate-on="blur"
            data-vv-as="last name"
            name="lastName"
            label-placeholder="Last Name"
            placeholder="Last Name"
            v-model="userDetail.lastName"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">
            {{ errors.first("lastName") }}
          </span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
        <vs-row vs-justify="flex-end">
          <vs-button color="success" @click="updateAdminProfileData"
            >Update</vs-button
          >
        </vs-row>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import { mapActions } from "vuex";

export default {
  name: "ProfileEdit",
  props: ["userData"],
  components: {
    Datepicker,
  },
  data() {
    return {
      userDetail: {},
      dataImg: null
    };
  },
  methods: {
    ...mapActions("user", ["updateAdminProfile", "updateAdminImage"]),
    customFormatter(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    updateAdminProfileData() {
      if(this.dataImg) {
        let adminData = {
          _id: this.userDetail._id,
          profileImage: this.dataImg
        }
        this.$vs.loading();
        this.updateAdminImage(adminData).then(response => {
          this.uploadedImage = response.data.data; 
          this.updateUserDetail();
        }).catch(error => {
          this.$vs.loading.close();
          console.log("Error", error);
        })
      }
    },
    updateUserDetail() {
      let user = JSON.parse(localStorage.getItem("user"));
      this.userDetail._id = user._id;
      let data = {
        _id: user._id,
        name:
          this.userDetail.firstName.trim() +
          " " +
          this.userDetail.lastName.trim(),
        profileImage: this.uploadedImage
      };
      this.updateAdminProfile(data).then((res) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Details Updated",
          text: res.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        localStorage.setItem("user", JSON.stringify(res.data.data));
      });
    },
     updateCurrImg(input) {
       console.log(input.target.files[0])
      if (input.target.files && input.target.files[0] && /\.(jpe?g|png|gif)$/i.test(input.target.files[0].name)) {
        var reader = new FileReader();
        reader.onload = e => {
          this.dataImg = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    }
  },
  async created() {
    this.userDetail = this.$store.state.AppActiveUser;
    this.userDetail.firstName = this.userDetail.name.split(" ")[0];
    this.userDetail.lastName = this.userDetail.name.split(" ")[1];
    this.userDetail.photoURL = this.userDetail.profileImage ? this.userDetail.profileImage : this.userDetail.photoURL;
    console.log(this.userDetail.userType);
  },
};
</script>

<style lang="scss">
.register-tabs-container {
  min-height: 517px;

  .con-tab {
    padding-bottom: 23px;
  }
}
</style>
