<template>
  <div>
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="firstName">First Name</label>
          <p id="firstName">{{ userDetail.firstName }}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="lastName">Last Name</label>
          <p id="lastName">{{ userDetail.lastName }}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="email">Email</label>
          <p id="email">{{ userDetail.email }}</p>
        </div>
      </vs-col>
      <!-- <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType !== 'admin' &&
          this.userDetail.userType !== 'superAdmin'
        "
      >
        <div class="w-full m-5">
          <label for="contactNumber">Contact Number</label>
          <p id="contactNumber">{{ userDetail.contactNumber }}</p>
        </div>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType !== 'admin' &&
          this.userDetail.userType !== 'superAdmin'
        "
      >
        <div class="w-full m-5">
          <label for="preferredName">Preferred Name</label>
          <p id="preferredName">{{ userDetail.preferredName }}</p>
        </div>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType !== 'admin' &&
          this.userDetail.userType !== 'superAdmin'
        "
      >
        <div class="w-full m-5">
          <label for="ahpraRegistrationNumber">Ahpra Registration Number</label>
          <p id="ahpraRegistrationNumber">
            {{ userDetail.ahpraRegistrationNumber }}
          </p>
        </div>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType !== 'admin' &&
          this.userDetail.userType !== 'superAdmin'
        "
      >
        <div class="w-full m-5">
          <label for="ahpraExiryDate">Ahpra Exiry Date</label>
          <p id="ahpraExiryDate">
            {{ userDetail.ahpraExiryDate | date_formatter }}
          </p>
        </div>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType !== 'admin' &&
          this.userDetail.userType !== 'superAdmin'
        "
      >
        <div class="w-full m-5">
          <label for="ahpraCertificate">Ahpra Certificate</label>
          <a :href="userDetail.ahpraCertificate" target="_blank">
            <p id="ahpraCertificate">View</p>
          </a>
        </div>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType !== 'admin' &&
          this.userDetail.userType !== 'superAdmin'
        "
      >
        <div class="w-full m-5">
          <label for="idemnityInsuranceProvider"
            >Indemnity Insurance Provider</label
          >
          <p id="idemnityInsuranceProvider">
            {{ userDetail.indemnityInsuranceProvider }}
          </p>
        </div>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType !== 'admin' &&
          this.userDetail.userType !== 'superAdmin'
        "
      >
        <div class="w-full m-5">
          <label for="idemnityInsuranceNumber"
            >Indemnity Insurance Number</label
          >
          <p id="idemnityInsuranceNumber">
            {{ userDetail.indemnityInsuranceNumber }}
          </p>
        </div>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType !== 'admin' &&
          this.userDetail.userType !== 'superAdmin'
        "
      >
        <div class="w-full m-5">
          <label for="insuranceCertificate">Insurance Certificate</label>
          <a :href="userDetail.insuranceCertificate" target="_blank">
            <p id="insuranceCertificate">View</p>
          </a>
        </div>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="left"
        vs-align="left"
        vs-w="6"
        v-if="
          this.userDetail.userType !== 'admin' &&
          this.userDetail.userType !== 'superAdmin'
        "
      >
        <div class="w-full m-5">
          <label for="idemnityInsuranceExpiry"
            >Indemnity Insurance Expiry</label
          >
          <p id="idemnityInsuranceExpiry">
            {{ userDetail.indemnityInsuranceExpiry | date_formatter }}
          </p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="status">Status</label>
          <p id="status">{{ userDetail.status }}</p>
        </div>
      </vs-col> -->
      <!-- <vs-col vs-type="flex" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="profileImage">Profile Image</label>
          <a
            style="display: block"
            id="profileImage"
            v-if="userDetail.profileImage"
            :href="userDetail.profileImage"
            target="_blank"
          >
            <vs-avatar size="100px" :src="userDetail.profileImage" />
          </a>
          <p v-else id="profileImage">NA</p>
        </div>
      </vs-col> -->
    </vs-row>
  </div>
</template>

<script>
export default {
  name: "ProfileDetail",
  data: () => ({
    userDetail: {},
  }),
  methods: {},
  async created() {
    this.userDetail = this.$store.state.AppActiveUser;
    this.userDetail.firstName = this.userDetail.name.split(" ")[0];
    this.userDetail.lastName = this.userDetail.name.split(" ")[1];
    console.log(this.userDetail.userType);
  },
};
</script>

<style>
p {
  font-size: 15px;
  font-weight: 500;
}
</style>
